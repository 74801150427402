@import '../../styles/colors';

.app-header {
    background: linear-gradient(60deg, $orange-highlight, $purple-highlight);
}

.app-main {
    background: linear-gradient(
        60deg,
        $gray-background,
        $light-gray-background
    );
    min-height: 100vh;
}

.app-container {
    background-color: $white;
    min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 100px;
}

.app-footer {
    background: linear-gradient(60deg, $orange-highlight, $purple-highlight);
}

.app-footer-container {
    background-color: rgba($color: $black, $alpha: 0.4);
    min-height: 20vh;
    color: $light-gray-background;
}

.MuiButton-root.example-button {
    margin-bottom: 15px;
}
